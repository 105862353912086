*, ::before, ::after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-x-0 {
  left: 0px;
  right: 0px;
}

.top-53 {
  top: 53px;
}

.left-25 {
  left: 25px;
}

.left-0 {
  left: 0px;
}

.top-0 {
  top: 0px;
}

.right-0 {
  right: 0px;
}

.bottom-0 {
  bottom: 0px;
}

.bottom-20 {
  bottom: 20px;
}

.left-1\/2 {
  left: 50%;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.m-0 {
  margin: 0px;
}

.m-32 {
  margin: 32px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-\[-18px\] {
  margin-top: -18px;
  margin-bottom: -18px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-6 {
  margin-bottom: 6px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-8 {
  margin-bottom: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-55 {
  margin-top: 55px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.h-screen {
  height: 100vh;
}

.h-24 {
  height: 24px;
}

.h-35 {
  height: 35px;
}

.h-60 {
  height: 60px;
}

.w-24 {
  width: 24px;
}

.w-\[95\%\] {
  width: 95%;
}

.w-35 {
  width: 35px;
}

.w-60 {
  width: 60px;
}

.w-\[40\%\] {
  width: 40%;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-none {
  flex: none;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1{
  gap:4px;
}

.gap-2{
  gap:8px;
}

.gap-4{
  gap:16px;
}

.gap-10 {
  gap: 10px;
}

.gap-8 {
  gap: 8px;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-\[12px\] {
  border-radius: 12px;
}

.rounded-\[24px\] {
  border-radius: 24px;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.border-4 {
  border-width: 4px;
}

.border {
  border-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-\[\#fff\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.bg-\[\#f5f6fc\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 246 252 / var(--tw-bg-opacity));
}

.bg-\[\#e8ecfb\] {
  --tw-bg-opacity: 1;
  background-color: rgb(232 236 251 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-70 {
  --tw-bg-opacity: 0.7;
}

.p-16 {
  padding: 16px;
}

.p-20 {
  padding: 20px;
}

.p-10 {
  padding: 10px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}

.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-14 {
  padding-top: 14px;
  padding-bottom: 14px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-8 {
  padding-left: 8px;
}

.pr-12 {
  padding-right: 12px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-20 {
  font-size: 20px;
}

.text-26 {
  font-size: 26px;
}

.text-12 {
  font-size: 12px;
}

.text-25 {
  font-size: 25px;
}

.text-14 {
  font-size: 14px;
}

.text-13 {
  font-size: 13px;
}

.font-bold {
  font-weight: 700;
}

.leading-8 {
  line-height: 2rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-\[24px\] {
  line-height: 24px;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-\[\#7780a0\] {
  --tw-text-opacity: 1;
  color: rgb(119 128 160 / var(--tw-text-opacity));
}

.text-\[\#5d61ff\] {
  --tw-text-opacity: 1;
  color: rgb(93 97 255 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-\[white\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-\[\#333333\] {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.outline {
  outline-style: solid;
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

* {
  margin: 0;
  padding: 0;
  line-height: 1;
}

@media (prefers-color-scheme: dark) {
  :root:root{
    --border-color: rgba(152, 161, 192, 0.24);
    --widget-bg-color: rgb(41, 50, 73);
    --theme-text-color: white;
    --background-color: #000000;
    --icon-color: rgb(152, 161, 192);
    --adm-color-text: white;
    --adm-color-primary: #5d61ff
    
  }
}

@media (prefers-color-scheme: light) {
  :root:root{
    --border-color:rgb(210, 217, 238);
    --widget-bg-color: rgb(232, 236, 251);
    --theme-text-color: #0d111c;
    --background-color: #f7f7f7;
    --icon-color: #7780a0;
    --adm-color-text: #0d111c;
    --adm-color-primary: #5d61ff
    
  }
}

body{
  margin:0;
  padding:0;
  background-color: var(--background-color);
  /* color: var(--theme-text-color) !important; */
}

:root:root{
  --text-primary-color: #1F2120;
  --text-second-color: #666666;
  --text-three-color: #999999;
  --second-bg:#FE3D54
  
}

div{
  line-height:1
}

.app{
  background-image: linear-gradient(to bottom, #EEEFFF, #f7f7f7)
}

.nav-bg.adm-nav-bar{
  background:#F6FBFB
}

.nav-bg .adm-nav-bar-back-arrow{
  color: var(--text-primary-color);
}

.adm-nav-bar-back-arrow{
  font-size:20px;
}

.bg-transparent .adm-nav-bar-back-arrow{
  color: white;
}

.text-primary{
  color: var(--text-primary-color);
}

.text-second{
  color: var(--text-second-color);
}

.text-three{
  color: var(--text-three-color);
}

.price{
  color: var(--second-bg);
}

.adm-auto-center-content{
  word-break: break-word;
}

.adm-list-default .adm-list-body{
  background-color: transparent;
}

.adm-swiper-indicator .adm-page-indicator{
  --active-dot-color: white;
  --dot-size: 5px;
  --active-dot-border-radius: 20px;
  --dot-color: white;
  --active-dot-size: 17px;
  --dot-border-radius:50%;
}

.border-top{
  border-top:1px solid #E9E9E9
}

.border-bottom{
  border-bottom:1px solid #E9E9E9
}

@media (prefers-color-scheme: dark) {
  .dark\:border-\[\#0d111c\] {
    --tw-border-opacity: 1;
    border-color: rgb(13 17 28 / var(--tw-border-opacity));
  }

  .dark\:bg-\[\#131a2a\] {
    --tw-bg-opacity: 1;
    background-color: rgb(19 26 42 / var(--tw-bg-opacity));
  }

  .dark\:bg-\[\#293249\] {
    --tw-bg-opacity: 1;
    background-color: rgb(41 50 73 / var(--tw-bg-opacity));
  }

  .dark\:bg-\[\#0d111c\] {
    --tw-bg-opacity: 1;
    background-color: rgb(13 17 28 / var(--tw-bg-opacity));
  }

  .dark\:bg-transparent {
    background-color: transparent;
  }

  .dark\:text-\[\#98a1c0\] {
    --tw-text-opacity: 1;
    color: rgb(152 161 192 / var(--tw-text-opacity));
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

@media (min-width: 768px) {
  .md\:w-\[450px\] {
    width: 450px;
  }
}