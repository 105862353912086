.plan-title{
text-align: center;
height: 20px;
line-height: 20px;
font-size: 15px;
color: var(--theme-text-color);
font-weight: bold;
letter-spacing: 0.05em;
}

.plan-content{
border-bottom: solid 0.5px var(--adm-color-border);
padding-bottom: 20px;
}

.plan-content > p {
overflow-wrap: break-word;
}

.plan-content > table {
width: 100%;
}

.plan-content > table th {
text-align: left;
}

.plan-content > table td {
text-align: left;
padding-top: 10px;
}

.plan-footer-purchase{
padding-top: 12px;
display: flex;
justify-content: center;
}

.plan-footer-order{
padding-top: 12px;
display: flex;
justify-content:center;
}

.vpninfo-loading{
display: flex;
justify-content: center;
margin-top: 20px; 
}

.detail-block {
margin-bottom: 16px;
}

.detail-title {
font-size: 14px;
line-height: 20px;
color: #9b9b9b;
}

.detail-content {
font-size: 14px;
line-height: 24px;
font-weight: 400;
margin: 0;
overflow-wrap: break-word;
}
